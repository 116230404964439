<template>
  <div>
    404 Not Found
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>