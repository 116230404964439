import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './style.css';
const pinia = createPinia();

const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => { 
        if (!(el == event.target || el.contains(event.target))) { 
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };
const app = createApp(App)
    .use(router)
    .directive("click-outside", clickOutside);

app.use(router).use(pinia).mount('#app');
