<template>
  <div class="fixed inset-0 w-full z-50">
    <div
      class="absolute inset-0 bg-black/40"
      @click="emit('closeAccount')"
    ></div>
    <div class="max-w-[680px] mx-auto pt-24 lg:pt-32 xl:pt-48 relative">
      <div class="p-6 relative rounded-xl bg-white space-y-6">
        <div class="flex justify-between items-center">
          <h3 class="font-bold text-xl text-black">Account</h3>
          <button
            class="underline text-primary text-[15px]"
            v-if="!is_edit"
            @click="is_edit = true; getUser()"
            type="button"
          >
            Edit
          </button>
          <button
            class="underline text-primary text-[15px]"
            v-else
            @click="is_edit = false; updateName()"
            type="button"
          >
            Save
          </button>
        </div>
        <form action="" class="space-y-7">
          <div v-if="is_edit" class="grid grid-cols-2 gap-x-8 gap-y-4">
            <div class="space-y-1">
              <label for="first-name" class="block text-xs text-black pl-2"
                >First Name</label
              >
              <input
                type="text"
                id="first-name"
                v-model="first_name"
                name="first_name"
                placeholder="First Name"
                class="bg-white border border-black text-[15px] text-dark placeholder:text-brand-gray px-3 py-2.5 rounded w-full focus:ouline-none focus:ring-0"

              />
            </div>
            <div class="space-y-1">
              <label for="last_name" class="block text-xs text-black pl-2"
                >Last Name</label
              >
              <input
                type="text"
                id="last-name"
                v-model="last_name"
                @input="updateLastName"
                name="last_name"
                placeholder="Last Name"
                class="bg-white border border-black text-[15px] text-dark placeholder:text-brand-gray px-3 py-2.5 rounded w-full focus:ouline-none focus:ring-0"
              />
            </div>
          </div>
          <div v-else class="grid grid-cols-2 gap-x-8 gap-y-4">
            <div class="space-y-1">
              <p class="block text-xs text-black pl-2">First Name</p>
              <p class="px-3 py-2.5 text-[15px] text-dark" >{{ first_name }}</p>
            </div>
            <div class="space-y-1">
              <p class="block text-xs text-black pl-2">Last Name</p>
              <p class="px-3 py-2.5 text-[15px] text-dark">{{ last_name }} </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import client, { setAuthHeader } from "../../../axios.config";
const emit = defineEmits(["closeAccount"]);
const is_edit = ref(false);
const user = JSON.parse(localStorage.getItem("user"));
const first_name = ref(user.firstname);
const last_name = ref(user.lastname);
const email = ref(user.email);


onMounted(async () => {
  getUser();
});


const updateName = async () => {
  console.log("updateName")
  const firstName = document.getElementById("first-name");
  const lastName = document.getElementById("last-name");
  first_name.value = firstName.value;
  last_name.value = lastName.value;
  await saveUser();
  getUser();
}

const getUser = async () => {
  try {
    const resp = await client.get(`/api/v1/users/${user.id}`);
    first_name.value = resp.data.firstname;
    last_name.value = resp.data.lastname;
  } catch (error) {
    console.log("error getting user", error)
  }
}


const saveUser = async () => {
  console.log("saveUser")
  try {
    const resp = await client.put(`/api/v1/users/${user.id}`, {
      firstname: first_name.value,
      lastname: last_name.value
    });
    console.log("data",resp.data);
  } catch (error) {
    console.log("error saving user", error);
  }


}



</script>
 
