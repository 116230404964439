<template>
  <div class="w-full">

    <div class="w-full flex items-center justify-between p-5 bg-black sticky top-0">
      <router-link to="/sales-rep/leads">
        <img src="../assets/images/logo.png" alt="">
      </router-link>
      <button class="logout-btn" @click="logout()">
        <img src="@/assets/images/logout.png" alt="logout" class="w-3 mr-2">
        Logout
      </button>
    </div>
    <router-view></router-view>
    <div class="fixed w-full bottom-0 shadow-6xl z-40 bg-white px-4">
      <div class="max-w-[440px] mx-auto">
        <ul class="flex py-4 gap-3 justify-between">
          <li class="">
            <router-link
              to="/sales-rep/leads"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <g clip-path="url(#clip0_37_882)">
                  <path
                    d="M6.25 10.8333C5.50832 10.8333 4.7833 10.6134 4.16661 10.2013C3.54993 9.78929 3.06928 9.20362 2.78545 8.5184C2.50162 7.83318 2.42736 7.07918 2.57206 6.35175C2.71675 5.62432 3.0739 4.95613 3.59835 4.43168C4.1228 3.90724 4.79098 3.55009 5.51841 3.40539C6.24584 3.2607 6.99984 3.33496 7.68506 3.61879C8.37029 3.90262 8.95596 4.38326 9.36801 4.99995C9.78007 5.61663 10 6.34166 10 7.08334C9.9989 8.07756 9.60345 9.03075 8.90043 9.73377C8.19741 10.4368 7.24422 10.8322 6.25 10.8333ZM12.5 16.6667C12.4987 15.562 12.0593 14.503 11.2781 13.7219C10.497 12.9407 9.438 12.5013 8.33333 12.5H4.16667C3.062 12.5013 2.00296 12.9407 1.22185 13.7219C0.440735 14.503 0.00132321 15.562 0 16.6667L0 20H12.5V16.6667ZM14.5833 7.5C13.8417 7.5 13.1166 7.28007 12.4999 6.86801C11.8833 6.45596 11.4026 5.87029 11.1188 5.18507C10.835 4.49984 10.7607 3.74584 10.9054 3.01841C11.0501 2.29098 11.4072 1.6228 11.9317 1.09835C12.4561 0.573904 13.1243 0.216751 13.8517 0.0720569C14.5792 -0.0726377 15.3332 0.00162482 16.0184 0.285453C16.7036 0.569282 17.2893 1.04993 17.7013 1.66661C18.1134 2.2833 18.3333 3.00832 18.3333 3.75C18.3322 4.74423 17.9368 5.69741 17.2338 6.40043C16.5307 7.10346 15.5776 7.4989 14.5833 7.5ZM15.8333 9.16667H11.6667C11.5204 9.17338 11.3746 9.18812 11.23 9.21084C10.927 9.91193 10.4795 10.5412 9.91667 11.0575C11.1386 11.4032 12.2145 12.1379 12.9814 13.15C13.7484 14.1622 14.1644 15.3968 14.1667 16.6667H20V13.3333C19.9987 12.2287 19.5593 11.1696 18.7782 10.3885C17.997 9.6074 16.938 9.16799 15.8333 9.16667Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_37_882">
                    <rect width="20" height="20" />
                  </clipPath>
                </defs>
              </svg>
              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Leads
              </p>
            </router-link>
          </li>
          <li class="">
            <router-link
              to="/sales-rep/sales"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM8.87 8.96667L11.4042 9.38917C12.5225 9.575 13.3333 10.5333 13.3333 11.6667C13.3333 13.045 12.2117 14.1667 10.8333 14.1667V15.8333H9.16667V14.1667C7.78833 14.1667 6.66667 13.045 6.66667 11.6667H8.33333C8.33333 12.1267 8.70667 12.5 9.16667 12.5H10.8333C11.2933 12.5 11.6667 12.1267 11.6667 11.6667C11.6667 11.3517 11.4408 11.085 11.13 11.0333L8.59583 10.6108C7.4775 10.425 6.66667 9.46667 6.66667 8.33333C6.66667 6.955 7.78833 5.83333 9.16667 5.83333V4.16667H10.8333V5.83333C12.2117 5.83333 13.3333 6.955 13.3333 8.33333H11.6667C11.6667 7.87417 11.2933 7.5 10.8333 7.5H9.16667C8.70667 7.5 8.33333 7.87417 8.33333 8.33333C8.33333 8.64833 8.55917 8.915 8.87 8.96667Z"
                />
              </svg>

              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Sales
              </p>
            </router-link>
          </li>
          <li class="">
            <router-link
              to="/sales-rep/account"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 11.25C13.1055 11.25 15.625 8.73047 15.625 5.625C15.625 2.51953 13.1055 0 10 0C6.89453 0 4.375 2.51953 4.375 5.625C4.375 8.73047 6.89453 11.25 10 11.25ZM15 12.5H12.8477C11.9805 12.8984 11.0156 13.125 10 13.125C8.98438 13.125 8.02344 12.8984 7.15234 12.5H5C2.23828 12.5 0 14.7383 0 17.5V18.125C0 19.1602 0.839844 20 1.875 20H18.125C19.1602 20 20 19.1602 20 18.125V17.5C20 14.7383 17.7617 12.5 15 12.5Z"
                />
              </svg>

              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Account
              </p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from 'vue-router';
  import { useAuth } from '../store/Auth';
  const router =  useRouter()
  const authStore = useAuth()
  const { logout } = authStore;

</script>
 <style>
  .logout-btn{
    /* box-shadow: 0 0 5px #00000020 inset; */
    @apply flex items-center justify-center text-primary-dark font-semibold px-4 py-1 rounded-md text-sm hover:underline
  }
</style>
