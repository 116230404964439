<template lang="">
    <header class="w-full bg-header py-7 px-4">
      <div class="flex justify-between items-center">
        <router-link to="/admin/dashboard">
          <figure>
            <img src="../../../assets/images/logo.png" alt="logo.png" />
          </figure>
        </router-link>
        <ul class="flex gap-3 sm:gap-5 items-center xl:pr-4">
          <li class="text-white text-sm">{{userRole === '' ? 'Checking':  userRole}}</li>
          <li>
            <a href="#"
              ><img src="../../../assets/images/bell.svg" alt="bell"
            /></a>
          </li>
          <li>
          <router-link to="/">
            <img src="../../../assets/images/user.svg" />
          </router-link>
        </li>
          <li>
            <button class="text-red-600" @click="logout()"
              >
              Logout
              </button>
          </li>
          <li class="flex items-center xl:hidden">
            <button type="button" @click="emit('openMenu')" class="inline-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-white"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </header>
  </template>
  <script setup>
import AccountPopUp from "./AccountPopUp.vue";
const emit = defineEmits(["openMenu"]);
import {ref, onMounted} from 'vue';
const userRole  = ref('');
import {useRouter} from 'vue-router';
import { useAuth } from '../../../store/Auth'
const router = useRouter();

const authStore = useAuth()
const { logout } = authStore;




onMounted(() =>  {
  userRole.value = localStorage.getItem('userRole'); 
})



const is_notification = ref(false);
const is_account = ref(false);

const notificationToggle = () => {
  is_notification.value = !is_notification.value;
};
const getOutsideClick = () => {
  is_notification.value = false;
};

const openAccount = () => {
  is_account.value = !is_account.value;
};

const closeAccount = () => {
  is_account.value = false;
};
</script>
  
