<template>
  <div class="bg-brand-gray-light">
    <Header @open-menu="menuOpen" />
    <div class="xl:flex min-h-[calc(100vh-90px)]">
      <Sidebar :is_menu="is_menu" @close-menu="menuClose" />
      <div class="px-4 lg:px-10 py-8 lg:py-10 w-full">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import Header from "../components/Admin/Layouts/Header.vue";
import Sidebar from "../components/Admin/Layouts/Sidebar.vue";
const is_menu = ref(false);
const route = useRoute();

const menuOpen = () => {
  is_menu.value = !is_menu.value;
};

const menuClose = () => {
  is_menu.value = false;
};

watch(route, (newQuery, oldQuery) => {
  is_menu.value = false;
});
</script>