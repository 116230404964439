import { defineStore } from "pinia";

export const useAuth = defineStore('auth', {
    state: () => ({
        isLoggedIn:false,
    }),
    actions: { 
        setAuth(newData) {
          this.allCompanies = newData;
        },
         logout(){
            console.log('logout called in auth');
            localStorage.removeItem('token');
            localStorage.removeItem('userRole');
            localStorage.removeItem('company');
            localStorage.removeItem('user');
            localStorage.removeItem('sid');
            localStorage.removeItem('driverId');
            window.location.href = '/';
          }
         
    },
})
