import axios from "axios";

const serverUrl = import.meta.env.VITE_APP_SERVER_URL || "http://localhost:8080";
console.log("Server URL is:" + serverUrl);
const client = axios.create({
  baseURL: serverUrl,
});
export const setAuthHeader = (token) => {
  client.defaults.headers.common["token"] = `${token}`;
  client.defaults.headers.common["Content-Type"] = "application/json";
};
const token = localStorage.getItem("token");
console.log("token from storage:" + token);
setAuthHeader(token);

const onSuccess = function (response) {
  return response.data;
};

const onError = function (error) {
  console.error("Request Failed:", error.config);
  if (error.response) {
  } else {
  }
  return Promise.reject({
    errMsg: !error?.response
      ? "Network Issue!"
      : error?.response?.data?.message ||
        (error?.response?.data?.errors[0]?.param +
          " " +
          error?.response?.data?.errors[0]?.msg),
    status: error?.response?.status || "not status",
  });
};

client.interceptors.response.use(onSuccess, onError);
export const baseURL = serverUrl;
export default client;
