<template>
  <header class="w-full bg-header py-7 px-4 relative z-[60]">
    <div class="flex justify-between items-center">
      <router-link to="/internal/dashboard">
        <figure>
          <img src="../../../assets/images/logo.png" alt="logo.png" />
        </figure>
      </router-link>
      <ul class="flex gap-3 sm:gap-5 items-center xl:pr-4">
        <li>
          <div
            class="relative group"
            v-click-outside="getOutsideClick"
            :class="is_notification ? 'active' : ''"
          >
<!--            <div class="relative flex items-center">
              <button type="button" @click="notificationToggle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g fill="#fff" clipPath="url(#clip0_4_1181)">
                    <path
                      d="M4.068 18h15.656a3 3 0 002.821-4.021l-2.852-7.885a8.323 8.323 0 00-16.14.422l-2.35 7.6A3 3 0 004.067 18zM7.1 20a5 5 0 009.8 0H7.1z"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_4_1181">
                      <path fill="#fff" d="M0 0H24V24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div
                class="w-2 h-2 rounded-full bg-primary absolute right-0 -top-0.5"
              ></div>
            </div>
-->

            <div
              class="mt-2 group-[.active]:opacity-100 z-40 group-[.active]:visible group-[.active]:translate-y-0 translate-y-8 transition-all duration-300 opacity-0 invisible absolute rounded-2.5xl bg-white p-4 shadow-4xl right-0 top-full w-[285px]"
            >
              <h3 class="font-bold text-xs text-black pb-1.5">Notifications</h3>
              <ul class="divide-y divide-black">
                <li class="py-2 first:pt-0 last:pb-0">
                  <router-link
                    to="/"
                    class="inline-block text-black text-[10px]"
                  >
                    Invoice payment overdue for
                    <strong>Resource Rethink</strong>
                  </router-link>
                </li>
                <li class="py-2 first:pt-0 last:pb-0">
                  <router-link
                    to="/"
                    class="inline-block text-black text-[10px]"
                  >
                    Invoice payment overdue for
                    <strong>Resource Rethink</strong>
                  </router-link>
                </li>
                <li class="py-2 first:pt-0 last:pb-0">
                  <router-link
                    to="/"
                    class="inline-block text-black text-[10px]"
                  >
                    Invoice payment overdue for
                    <strong>Resource Rethink</strong>
                  </router-link>
                </li>
                <li class="py-2 first:pt-0 last:pb-0">
                  <router-link
                    to="/"
                    class="inline-block text-black text-[10px]"
                  >
                    Invoice payment overdue for
                    <strong>Resource Rethink</strong>
                  </router-link>
                </li>
                <li class="py-2 first:pt-0 last:pb-0">
                  <router-link
                    to="/"
                    class="inline-block text-black text-[10px]"
                  >
                    Invoice payment overdue for
                    <strong>Resource Rethink</strong>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <!-- <router-link to="/">
            <img src="../../../assets/images/user.svg" />
          </router-link> -->
          <button type="button" @click="openAccount">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="none"
              viewBox="0 0 28 28"
            >
              <g fill="#fff" clipPath="url(#clip0_4_1178)">
                <path
                  d="M18.717 16.334H9.283A5.79 5.79 0 003.5 22.117V28h21v-5.883a5.79 5.79 0 00-5.783-5.784zM14 14a7 7 0 100-14 7 7 0 000 14z"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_4_1178">
                  <path fill="#fff" d="M0 0H28V28H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </button>
        </li>
        <li>
          <button
            v-if="isEmpty === false"
            class="text-red-600"
            @click="logout"
          >
            <!-- <img src="../../../assets/images/user.svg" alt="user" /> -->
            <span>Logout</span>
          </button>
          <button v-else class="text-red-600" @click="logout"></button>
        </li>

        <li class="flex items-center xl:hidden">
          <button type="button" @click="emit('openMenu')" class="inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-white"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </header>
  <AccountPopUp v-if="is_account" @closeAccount="closeAccount" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "../../../store/Auth";
const authStore = useAuth()
const { logout } = authStore
const isEmpty = ref(false);

const router = useRouter();
const emit = defineEmits(["openMenu"]);

onMounted(() => {
  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("userRole") === null ||
    localStorage.getItem("company") === null
  ) {
    isEmpty.value = true;
    console.log("Local storage is empty.");
  } else {
    isEmpty.value = false;
    console.log("Local storage is not empty.");
  }
});

import AccountPopUp from "./AccountPopUp.vue";


const is_notification = ref(false);
const is_account = ref(false);

const notificationToggle = () => {
  is_notification.value = !is_notification.value;
};
const getOutsideClick = () => {
  is_notification.value = false;
};

const openAccount = () => {
  is_account.value = !is_account.value;
};

const closeAccount = () => {
  is_account.value = false;
};
</script>
