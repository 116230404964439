import { createRouter, createWebHashHistory } from "vue-router";

import auth from "../layouts/Auth.vue";
import admin from "../layouts/admin.vue";
import main from "../layouts/default.vue";
import driver from "../layouts/driver.vue";
import internal from "../layouts/internal.vue";
import salesRep from "../layouts/salesRep.vue";
import Success from "../pages/SuccessPage.vue";
import NotFound from '../pages/NotFound.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/index.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../pages/SuccessPage.vue"),
    meta: {
      layout: main,
    },
  },

  // for internal dashboard
  {
    path: "/internal/login",
    name: "InternalLogin",
    component: () => import("../pages/Internal/Auth/Login.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/internal/signup",
    name: "InternalSignup",
    component: () => import("../pages/Internal/Auth/Signup.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/internal/forgot-password",
    name: "InternalforgotPassword",
    component: () => import("../pages/Internal/Auth/forgotPassword.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/internal/dashboard",
    name: "Internal",
    component: () => import("../pages/Internal/index.vue"),
    meta: {
      layout: internal,
      role: "portal",
    },
  },
  {
    path: "/internal/customer/details/:id",
    name: "CustomerDetails",
    component: () => import("../pages/Internal/Customers/details.vue"),
    meta: {
      layout: internal,
      role: "portal",
       

    },
  },
  {
    path: "/admin/customer/details/:id",
    name: "CustomerAdminDetails",
    component: () => import("../pages/Admin/Customers/details.vue"),
    meta: {
      layout: admin,
      role: "admin", 
    },
  },
  {
    path: "/internal/customer/edit/:id",
    name: "customerEdit",
    component: () => import("../pages/Internal/Customers/edit.vue"),
    meta: {
      layout: internal,
      role: "portal",
    },
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import('../pages/CompanyClient/Confirmation.vue'),
    meta:{
      layout: main
    }
  },
  {
    path: "/internal/billing",
    name: "billing",
    component: () => import("../pages/Internal/Billing.vue"),
    meta: {
      layout: internal,
      role: "portal",

    },
  },
    {
        path: '/sales-rep/account',
        name: 'SalesAccount',
        component: () => import('../pages/SalesRep/Account.vue'),
        meta:{
            layout: salesRep
        }
    },

  // for admin area
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () => import("../pages/Admin/Auth/Login.vue"),
    meta: {
      layout: auth,
    },
  },

  {
    path: "/admin/success",
    name: "AdminSuccess",
    component: () => import("../pages/SuccessPage.vue"),
    meta:{
      layout: main
    }
     
  },


  {
    path: "/admin/signup",
    name: "AdminSignup",
    component: () => import("../pages/Admin/Auth/Signup.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/admin/forgot-password",
    name: "AdminforgotPassword",
    component: () => import("../pages/Admin/Auth/forgotPassword.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () => import("../pages/Admin/Dashboard.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/quote",
    name: "AdminQuotes",
    component: () => import("../pages/Admin/Quotes.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/create-route",
    name: "AdminCreateQuotes",
    component: () => import("../pages/Admin/CreateRoute.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/services/create",
    name: "AdminCreateServices",
    component: () => import("../pages/Admin/CreateServices.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/create-quotes",
    name: "createQuotes",
    component: () => import("../pages/Admin/CreateQuotes.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/update-quote/:id",
    name: "updateQuotes",
    component: () => import("../pages/Admin/UpdateQuote.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/fog/reports/:id",
    name: "createFog",
    component: () => import("../pages/Admin/FogReports.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/fog/reports/create/:id",
    name: "createFogReport",
    component: () => import("../pages/Admin/CreateFog.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/update/brick/:id",
    name: "updateBrick",
    component: () => import("../pages/Admin/UpdateBrick.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/customer/create",
    name: "CustomerCreate",
    component: () => import("../pages/Admin/CreateCustomers.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/customer",
    name: "Customer",
    component: () => import("../pages/Admin/Customer.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/routes",
    name: "Routes",
    component: () => import("../pages/Admin/Routes.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/drivers",
    name: "Drivers",
    component: () => import("../pages/Admin/Drivers.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/create-driver",
    name: "CreateDrivers",
    component: () => import("../pages/Admin/CreateDrivers.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/leads",
    name: "adminLeads",
    component: () => import("../pages/Admin/Leads.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/leads/create",
    name: "adminLeadsCreate",
    component: () => import("../pages/Admin/CreateLead.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },
  {
    path: "/admin/leads/details/:id",
    name: "adminLeadsDetails",
    component: () => import("../pages/Admin/LeadDetails.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  {
    path: "/admin/quote-details",
    name: "QuotesDetails",
    component: () => import("../pages/Admin/QuotesDetails.vue"),
    meta: {
      layout: admin,
      role: "admin",
    },
  },

  // for restaurend client routes
  {
    path: "/restaurant-client/:id",
    name: "RestaurentClient",
    component: () => import("../pages/CompanyClient/RestaurantClient.vue"),
    meta: {
      layout: main,
    },
  },

  // for sales-rep view routes
  {
    path: "/sales-rep/login",
    name: "SalesLogin",
    component: () => import("../pages/SalesRep/Auth/Login.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/sales-rep/signup",
    name: "SalesSignup",
    component: () => import("../pages/SalesRep/Auth/Signup.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/sales-rep/forgot-password",
    name: "SalesforgotPassword",
    component: () => import("../pages/SalesRep/Auth/forgotPassword.vue"),
    meta: {
      layout: auth,
      
    },
  },
  {
    path: "/sales-rep/leads",
    name: "Leads",
    component: () => import("../pages/SalesRep/Leads.vue"),
    meta: {
      layout: salesRep,
      role:'salesRep'

    },
  },
  {
    path: "/sales-rep/sales",
    name: "Sales",
    component: () => import("../pages/SalesRep/Sales.vue"),
    meta: {
      layout: salesRep,
      role:'salesRep'
    },
  },

  // for driver routes
  {
    path: "/driver/login",
    name: "DriverLogin",
    component: () => import("../pages/Driver/Auth/Login.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/driver/signup",
    name: "DriverSignup",
    component: () => import("../pages/Driver/Auth/Signup.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/driver/forgot-password",
    name: "DriverforgotPassword",
    component: () => import("../pages/Driver/Auth/forgotPassword.vue"),
    meta: {
      layout: auth,
    },
  },
  {
    path: "/driver/home",
    name: "DriverHome",
    component: () => import("../pages/Driver/Home.vue"),
    meta: {
      layout: driver,
      role: "driver",

    },
  },
  {
    path: "/driver/schedule",
    name: "DriverSchedule",
    component: () => import("../pages/Driver/Schedule.vue"),
    meta: {
      layout: driver,
      role: "driver",
    },
  },
  {
    path: "/*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: '/driver/account',
    name: 'DriverAccount',
    component: () => import('../pages/Driver/Account.vue'),
    meta:{
      layout: driver
    }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


//router.beforeEach((to, from, next) => {
//  const role = to.meta.role;
//
//  // Check if you're already on the root path ("/")
//  if (to.path === "/" && from.path === "/") {
//    next(); // Avoid redirection
//    return;
//  }
//
//  if (localStorage.getItem("userRole") !== null) {
//    const userRoles = localStorage.getItem("userRole").split(",");
//
//    if (userRoles.includes("portal")) {
//      // Allow access to all pages for users with the "portal" role.
//      next();
//    } else if (userRoles.includes(role)) {
//      // Allow access to the current route for other roles.
//      next();
//    } else {
//      // Redirect to the home page if the user doesn't have the required role.
//      next('/');
//    }
//  } else {
//    // Redirect to the home page for unauthenticated users.
//    if (to.name !== "AdminLogin" && to.name !== "Home" && to.name !== "DriverLogin" && to.name !== "InternalLogin" && to.name !== "SalesLogin" && to.name !== "RestaurentClient" && to.name !== "AdminSignup" && to.name !== "InternalSignup" && to.name !== "DriverSignup" && to.name !== "SalesSignup") {
//      // Handle 404 errors by redirecting to the NotFound page
//      next({ name: "NotFound" });
//    } else {
//      next();
//    }
//  }
//});


export default router;
