<template>
  <div>
    <div class="bg-brand-gray-light">
      <div class="min-h-screen flex items-center justify-center">
        <div class="px-4 lg:px-10 py-8 lg:py-10">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
  