<template>
  <div class="w-full">
    <router-view></router-view>
    <div class="fixed w-full bottom-0 shadow-6xl z-40 bg-white px-4">
      <div class="max-w-[440px] mx-auto">
        <ul class="flex py-4 gap-3 justify-between">
          <li class="">
            <router-link
              to="/driver/home"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 10.8333C5.50832 10.8333 4.7833 10.6134 4.16661 10.2013C3.54993 9.78929 3.06928 9.20362 2.78545 8.5184C2.50162 7.83318 2.42736 7.07918 2.57206 6.35175C2.71675 5.62432 3.0739 4.95613 3.59835 4.43168C4.1228 3.90724 4.79098 3.55009 5.51841 3.40539C6.24584 3.2607 6.99984 3.33496 7.68506 3.61879C8.37029 3.90262 8.95596 4.38326 9.36801 4.99995C9.78007 5.61663 10 6.34166 10 7.08334C9.9989 8.07756 9.60345 9.03075 8.90043 9.73377C8.19741 10.4368 7.24422 10.8322 6.25 10.8333ZM12.5 16.6667C12.4987 15.562 12.0593 14.503 11.2781 13.7219C10.497 12.9407 9.438 12.5013 8.33333 12.5H4.16667C3.062 12.5013 2.00296 12.9407 1.22185 13.7219C0.440735 14.503 0.00132321 15.562 0 16.6667L0 20H12.5V16.6667ZM14.5833 7.5C13.8417 7.5 13.1166 7.28007 12.4999 6.86801C11.8833 6.45596 11.4026 5.87029 11.1188 5.18507C10.835 4.49984 10.7607 3.74584 10.9054 3.01841C11.0501 2.29098 11.4072 1.6228 11.9317 1.09835C12.4561 0.573904 13.1243 0.216751 13.8517 0.0720569C14.5792 -0.0726377 15.3332 0.00162482 16.0184 0.285453C16.7036 0.569282 17.2893 1.04993 17.7013 1.66661C18.1134 2.2833 18.3333 3.00832 18.3333 3.75C18.3322 4.74423 17.9368 5.69741 17.2338 6.40043C16.5307 7.10346 15.5776 7.4989 14.5833 7.5ZM15.8333 9.16667H11.6667C11.5204 9.17338 11.3746 9.18812 11.23 9.21084C10.927 9.91193 10.4795 10.5412 9.91667 11.0575C11.1386 11.4032 12.2145 12.1379 12.9814 13.15C13.7484 14.1622 14.1644 15.3968 14.1667 16.6667H20V13.3333C19.9987 12.2287 19.5593 11.1696 18.7782 10.3885C17.997 9.6074 16.938 9.16799 15.8333 9.16667Z"
                />
              </svg>

              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Home
              </p>
            </router-link>
          </li>
          <li class="">
            <router-link
              to="/driver/schedule"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0714 2.5H14.1429V0.46875C14.1429 0.210938 13.9259 0 13.6607 0H13.3393C13.0741 0 12.8571 0.210938 12.8571 0.46875V2.5H5.14286V0.46875C5.14286 0.210938 4.92589 0 4.66071 0H4.33929C4.07411 0 3.85714 0.210938 3.85714 0.46875V2.5H1.92857C0.863839 2.5 0 3.33984 0 4.375V18.125C0 19.1602 0.863839 20 1.92857 20H16.0714C17.1362 20 18 19.1602 18 18.125V4.375C18 3.33984 17.1362 2.5 16.0714 2.5ZM1.92857 3.75H16.0714C16.425 3.75 16.7143 4.03125 16.7143 4.375V6.25H1.28571V4.375C1.28571 4.03125 1.575 3.75 1.92857 3.75ZM16.0714 18.75H1.92857C1.575 18.75 1.28571 18.4688 1.28571 18.125V7.5H16.7143V18.125C16.7143 18.4688 16.425 18.75 16.0714 18.75ZM5.94643 12.5H4.33929C4.07411 12.5 3.85714 12.2891 3.85714 12.0312V10.4688C3.85714 10.2109 4.07411 10 4.33929 10H5.94643C6.21161 10 6.42857 10.2109 6.42857 10.4688V12.0312C6.42857 12.2891 6.21161 12.5 5.94643 12.5ZM9.80357 12.5H8.19643C7.93125 12.5 7.71429 12.2891 7.71429 12.0312V10.4688C7.71429 10.2109 7.93125 10 8.19643 10H9.80357C10.0687 10 10.2857 10.2109 10.2857 10.4688V12.0312C10.2857 12.2891 10.0687 12.5 9.80357 12.5ZM13.6607 12.5H12.0536C11.7884 12.5 11.5714 12.2891 11.5714 12.0312V10.4688C11.5714 10.2109 11.7884 10 12.0536 10H13.6607C13.9259 10 14.1429 10.2109 14.1429 10.4688V12.0312C14.1429 12.2891 13.9259 12.5 13.6607 12.5ZM9.80357 16.25H8.19643C7.93125 16.25 7.71429 16.0391 7.71429 15.7812V14.2188C7.71429 13.9609 7.93125 13.75 8.19643 13.75H9.80357C10.0687 13.75 10.2857 13.9609 10.2857 14.2188V15.7812C10.2857 16.0391 10.0687 16.25 9.80357 16.25ZM5.94643 16.25H4.33929C4.07411 16.25 3.85714 16.0391 3.85714 15.7812V14.2188C3.85714 13.9609 4.07411 13.75 4.33929 13.75H5.94643C6.21161 13.75 6.42857 13.9609 6.42857 14.2188V15.7812C6.42857 16.0391 6.21161 16.25 5.94643 16.25ZM13.6607 16.25H12.0536C11.7884 16.25 11.5714 16.0391 11.5714 15.7812V14.2188C11.5714 13.9609 11.7884 13.75 12.0536 13.75H13.6607C13.9259 13.75 14.1429 13.9609 14.1429 14.2188V15.7812C14.1429 16.0391 13.9259 16.25 13.6607 16.25Z"
                  fill="#BEB705"
                />
              </svg>

              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Schedule
              </p>
            </router-link>
          </li>
          <li class="">
            <router-link
              to="/driver/account"
              class="group flex flex-col items-center justify-center gap-1.5"
            >
              <svg
                class="group-[.router-link-exact-active]:fill-primary fill-primary-50"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 11.25C13.1055 11.25 15.625 8.73047 15.625 5.625C15.625 2.51953 13.1055 0 10 0C6.89453 0 4.375 2.51953 4.375 5.625C4.375 8.73047 6.89453 11.25 10 11.25ZM15 12.5H12.8477C11.9805 12.8984 11.0156 13.125 10 13.125C8.98438 13.125 8.02344 12.8984 7.15234 12.5H5C2.23828 12.5 0 14.7383 0 17.5V18.125C0 19.1602 0.839844 20 1.875 20H18.125C19.1602 20 20 19.1602 20 18.125V17.5C20 14.7383 17.7617 12.5 15 12.5Z"
                />
              </svg>

              <p
                class="text-xs font-bold group-[.router-link-exact-active]:text-primary text-primary-50"
              >
                Account
              </p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
 